<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=0 />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">注销账号<em></em></h3>
              <div class="info-cnt">
                <div><h3><i class="icon iconfont icon-ziyuanxhdpi"></i> <span>申请注销牛豆豆账号</span> </h3></div>
               
                <div class="tips" v-show="ishidetips" style="width:90%;margin:0 auto;">
                   <div class="tip-title">您提交申请注销账号生效之前，牛豆豆团队将进行验证，以保证你的账号、财产安全</div>
                  <el-collapse v-model="activeNames" @change="handleChange">
                    <el-collapse-item title="1.账号处于完全状态" name="1">
                      <div>账号没有被盗，被封等风险</div>
                    </el-collapse-item>
                    <el-collapse-item title="2.账户财产权益已结清" name="2">
                      <div>所有交易已完成且无纠纷（确认收货代表已完成）</div>
                    </el-collapse-item>
                  </el-collapse>
                  <div class="sub-btn " style="margin-top:20px;">
                    <el-button @click="onapply">申请注销</el-button>
                  </div>
                </div>
                <div v-show="!ishidetips" class="content-rb resetpwd">
                  <el-form :rules="rules" :model="ruleForm" ref="ruleForm" label-width="0" class="demo-ruleForm">
                    <div class="container">
                      <el-form-item label="手机号" prop="newUserMobile">
                        <span style="width: 300px;border:none;">{{ruleForm.newUserMobile}}</span><a href="javascript:;"> <span style="border:none;color:#3194fd;" @click="getCode('ruleForm')">{{codeMsg}}</span></a>
                      </el-form-item>
                      <el-form-item prop="verifCode" label="验证码">
                        <el-input style="width:300px" label="验证码" class="input-number" type="text" placeholder="请输入验证码"
                          v-model="ruleForm.verifCode" />
                       
                      </el-form-item>

                      <div class="sub-btn">
                        <el-button @click="submit('ruleForm')">提交</el-button>
                      </div>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import userleft from '@/components/userleft.vue';
import { senduserlogoffsms, userlogoffbysms } from '@/api/user';
import Cookies from 'js-cookie';
import {getCookieByName,removeCookieByName} from '@/utils/helper';

export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          return callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      ishidetips:true,
      activeNames: ['1', '2'],
      labelPosition: 'right',
      firstShow: true,
      show: false,
      height: '600',
      // 确认密码
      rules: {
        newUserMobile: [{ validator: checkPhone, trigger: 'blur' }],
        verifCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
      ruleForm: {
        newUserMobile: '',
        verifCode: '',
      },
      codeMsg: '获取验证码',
      second: 60,
      timer: null,
    };
  },
  methods: {
      onapply(){
          this.ishidetips=false;
      },
    changeInput() {
      var phone = /^[1]([3-9])[0-9]{9}$/;
      this.dbget = false;
      if (phone.test(this.ruleForm.newUserMobile)) {
        this.dbget = true;
        this.num = 1;
      }
    },

    getCode(formName) {
      this.dbget = true;
      if (this.ruleForm.newUserMobile == '') {
        this.$message.error('请输入手机号');
        return;
      }
      if (this.second != 60) {
        this.$message.error(`请${this.second}秒后重试`);
        return;
      }
      senduserlogoffsms({
        userMobile: this.ruleForm.newUserMobile,
      }).then((res) => {
        if (res.data.code === 1) {
          this.$message({
            message: '发送成功',
            type: 'success',
          });
          this.codeMsg = `${this.second}s后重试`;
          this.timer = setInterval(() => {
            this.second--;
            this.codeMsg = `${this.second}s后重试`;
            if (this.second == 0) {
              clearInterval(this.timer);
              this.second = 60;
              this.codeMsg = '获取验证码';
            }
          }, 1000);
        } else {
          this.$message.error(res.data.message);
          //this.phone = false;
        }
      });
      return;
    },

    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var form = {
            verifyCode: this.ruleForm.verifCode,
          };
          var that = this;
          userlogoffbysms(form).then((res) => {
            if (res.data.code === 1) {
              this.$message.success('注销成功');
              this.removeCookies();
              setTimeout(function () {
                window.location.href = '/';
              }, 300);
            } else {
              this.$message.error(res.data.message);
            }
          });
          return;
        } else {
          return false;
        }
      });
    },
    removeCookies() {
      removeCookieByName('access_token');
      removeCookieByName('user_account');
      removeCookieByName('refresh_token');
      removeCookieByName('user_id');
      removeCookieByName('iscompanyauthsuccess');
    },
  },
  mounted() {
   this.ruleForm.newUserMobile= getCookieByName('user_account');
  },
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/

.accountset >>> form.elform .phone .el-input {
  width: 220px;
  margin-right: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.sendcode {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}

.accountset .info-cnt .input-number {
  height: 42px;
  border: none;
  /* float: left; */
}
.accountset .info-cnt .message-number {
  margin-top: 3px;
}
.accountset .info-cnt .container-zh-right {
  float: left;
  position: relative;
}
.accountset  .info-cnt form span {
  font-size: 14px;
  line-height: 31px;
  margin-right: 10px;
  color: #3194fd;
  cursor: pointer;
  top: 0;
  margin-left: 5px;
  border: 1px solid #ddd;
  padding: 3px 10px;
  padding: 11px;
  border-radius: 5px;
  color: #8a8a8a;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.accountset .info-cnt .sub-btn {
  text-align: center;
}
.accountset .info-cnt .btn.sel {
  background-color: #228bff;
  border: 0;
  color: #fff;
}
.accountset .info-cnt .btn {
  width: 200px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  background: #fff;
  cursor: pointer;
}
input {
  outline: 0;
}

.el-input__suffix {
  z-index: 100 !important;
}

.accountset .info-cnt .el-button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
  width: 120px;
}
.account-content >>> .el-form-item__error {
  left: 84px;
}
.account-content>>> .el-collapse-item__header {
  font-weight: 600;
  font-size: 16px;
}
.account-content >>>.el-collapse-item__content{
    padding-left:1px;
}
.account-content .tip-title{padding:10px 0;font-size: 15px;color: #888;}
.account-content .info-cnt h3{text-align: center;padding-bottom:30px;}
.account-content .info-cnt h3 i,.account-content .info-cnt h3 span{display: block;width: 100%;font-size: 18px;margin: 5px 0;}
.account-content .info-cnt h3 i{font-size: 36px;color: #5aa5ff;}
</style>
